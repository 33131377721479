import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import userStore from "@horo/core/modules/user/store";
import categoryStore from "@horo/core/modules/category/store";
import envConfig from "../config/envConfig";
import categoryData from '../../core/i18n/resource/baydin_categories.json';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        paymentName: " ",
        appName: "",
        backendUrl: envConfig.appBackendUrl,
        backendBPpayUrl: envConfig.appBackendBPpayUrl,
        backendMpiteSanUrl: envConfig.appBackendMpiteSanUrl,
        backendOnepayUrl: envConfig.appBackendOnePayUrl,
        backendCitizenPayUrl: envConfig.appBackendCitizenPayUrl,
        paymentPortalUrl: envConfig.paymentPortalURL,
        paymentPortalV3Url: envConfig.paymentPortalV3URL,
        citizenpayCheckPaymentStatusURL: envConfig.citizenpayCheckPaymentStatus,
        
        merchant_id: envConfig.bpPayMpitesanMerchantId,
        service_name: envConfig.bpPayMpitesanServiceName,
        email: envConfig.bpPayMpitesanEmail,
        password: envConfig.bpPayMpitesanPassword,
        merchant_key: envConfig.bpPayMpitesanKey,
        amount: 0,
        order_id: "",
        onepayPhoneNumber: "",

        //from  coupon baydin category data
        categoryData : categoryData['default'],
        coupon_apps : envConfig.coupon_apps,
        //get coupon code from env
        coupon_code : envConfig.coupon_code,
        discount_coupon : "",
    },
    mutations: {
        getPaymentName(state, paymentName) {
            state.paymentName = paymentName;

        },

        

        //for coupon
        setCoupon(state){
            state.categoryData = categoryData['coupon_1000'];
        },

        setDiscountCoupon(state,coupon_code){
            state.discount_coupon = coupon_code;
        }
    },
    actions: {

        //for oordeoo coupon
        async changeCoupon({commit}) {
            commit('setCoupon')
        },

        async changeDiscountCoupon({commit},coupon_code) {
            commit('setDiscountCoupon',coupon_code)
        }
    },
    getters: {
        getRoutePath() {
            return router.currentRoute.path;
        },
        getRouteParams() {
            return router.currentRoute.params;
        },

        //from coupon baydin category data
        getCategoryData: (aState) => aState.categoryData,
        
        //To show coupon on decision
        couponDecision(aState) {
            return aState.coupon_apps.includes(aState.paymentName);
        },

        //get coupon code from env
        getCouponCode(aState) {
            return aState.coupon_code;
        },
        getDiscountCoupon({discount_coupon}){
            return discount_coupon;
        }
        ,
        getAppName({paymentName}){
            return paymentName;
        }
    },
    modules: {
        userStore,
        categoryStore,
    },
});
