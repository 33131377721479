<template>
  <section class="ds s-pt-30 s-pb-20 s-pt-xl-60 s-pb-xl-40 c-gutter-60 c-mb-30">
    <div class="container">
      <div class="row" id="box">
        <div class="col-xl-12 col-lg-12 col-12">
          <div
            class="vertical-item text-center service-layout-1 content-padding p-3"
            style="border: 3px solid #3e1e68; border-radius: 6px"
            @click="
              $router.push({
                name: 'orders',
              })
            "
          >
            1875 Baydin Answers
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-12">
          <div
            class="vertical-item text-center service-layout-1 content-padding p-3"
            style="border: 3px solid #3e1e68; border-radius: 6px"
            @click="
              $router.push({
                name: 'newyearOrder',
              })
            "
          >
            New Year Baydin Answers
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      category: "",
    };
  },
  created() {},
};
</script>
