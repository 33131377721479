<template>
  <div class="featured-home-post">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
      scoped
    />
    <section class="container-fluid mt-3">
      <div
        class="card p-4"
        style="border-color: #eb6224 !important; border-radius: 20px !important"
      >
        <div>
          <h4>
            {{ blog.name }} ( {{ blog.date_of_birth }}, {{ blog.day }} ) အတွက်
            တစ်နှစ်စာ ဟောစာတမ်း
          </h4>
          <div class="row p-0 m-0" style="justify-content: center">
            <div style="justify-content: center">
              <div v-html="blog.baydin_content.contents"></div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </section>
    <!-- end .featured-home-post -->
  </div>
</template>

<script>
import { ONEYEAR } from "@core/lib/http-common";

export default {
  name: `orderDetail`,
  data() {
    return {
      blog: "",
    };
  },
  created() {
    this.blog = this.$route.params.detail;
    console.log("answer read status value");
    localStorage.setItem("answerrsv", this.blog);
    console.log(this.blog);
    ONEYEAR.post("answerReadStatus", {
      Id: this.blog.id,
    });
  },
};
</script>
<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 15px;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

h4 {
  color: #eb6224;
  font-weight: 500;
  margin-bottom: 20px;
}

h1 {
  color: #fff;
  display: inline-block;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  background-color: #eb6224;
}

img {
  max-width: 100%;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

textarea {
  resize: none;
}

/* :focus {
            outline: none;
        } */

/* .wrapper {
  align-items: center;
  float: left;

  margin-top: 20px;
} */

.inner {
  display: flex;
  margin-left: 270px;
}

.slider {
  margin-left: -20px;
}

.option {
  color: #eb6224;
  text-align: center;
  width: 30px;
  font-weight: 300;
}

/* .baydin-form {
  background: white;
  padding-top: 63px;
  padding-left: 49px;
  padding-right: 50px;
  margin-top: -10px;

  margin-right: 10px;
  border: 2px solid #eb6224;
  border-radius: 25px;
} */

/* .subscribe {
  border-radius: 5px;
  width: 34%;
  border: 3px solid #e1e1e1;
  padding: 45px 35px;
  text-align: center;
  margin-right: 90px;
  box-sizing: border-box;
} */

.btn {
  height: 42px;
  width: 100px;
  border-radius: 7.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 13px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  background: #eb6224;
  padding-bottom: 5px;
  margin-right: 15px;
}

.baydin_title {
  font-family: PT-Serif-Bold, "MyanmarAngoun", "Arial", sans-serif;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #232323;
}

.container {
  margin-top: 50px;
  padding-left: 30px;
}

h3 {
  text-align: center;
  margin-bottom: 38px;
  color: white;
}

@media (max-width: 590px) {
  /* .baydin-form {
    height: auto;
    margin-left: auto;
    margin-top: 40px;
    padding-left: 30px;
  } */

  h4 {
    font-size: 19px;
  }
}

@media (max-width: 890px) {
  /* .baydin-form {
    height: auto;
    width: 350px;
    margin-left: auto;
    padding-left: 30px;
    margin-top: 40px;
  } */

  .inner {
    margin-left: auto;
  }
}

@media (max-width: 1190px) {
}

/*# sourceMappingURL=style.css.map */
</style>
