<template>
    <div></div>
</template>

<script>
    import {HTTP } from "@core/lib/http-common";
    import { mapGetters } from "vuex";
    export default {
        name: "OnePayRedirect",
        created() {
            this.$swal("အောင်မြင်ပါသည်။", "ကျေးဇူးပြု၍ခနစောင့်ဆိုင်းပေးပါ", "success");
        },
        computed: {
            ...mapGetters({
                getUserToken: "userStore/getUserToken",
            }),
        },
        mounted() {
            setTimeout(() => this.checkRedirect(), 2000);
        },
        methods: {
            checkRedirect() {
                HTTP.post('onepay/redirect-check', {
                    data: this.$route.params.data
                }).then((res) => {
                    let data = res.data;
                    if(data.status == "success") {
                        this.$router.push({
                            name: "PaymentSuccess",
                            params: { payment: "onepay" },
                            query: {category_id: data.category_id}
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.$router.push({
                        name: "withPaymenthome",
                        params: {
                            payment: "onepay"
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>

</style>
