<template>
  <div>
    <!-- <h1>Dear customer your params is as below</h1>
    <p>Payment Method : {{ paymentMethod }}</p>
    <p>
        Centent Category : {{ contentCategory }}
    </p> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      paymentMethod: "",
      contentCategory: "",
    };
  },
  created() {
    this.paymentMethod = this.$route.params.paymentMethod;
    this.contentCategory = this.$route.params.contentCategory;
    this.$store.commit("getPaymentName", this.paymentMethod);

    if (this.$store.state.paymentName == "mpitesan") {
      this.$store.state.backendUrl = this.$store.state.backendMpiteSanUrl;
    } else if (this.$store.state.paymentName == "onepay") {
      this.$store.state.backendUrl = this.$store.state.backendOnepayUrl;
      this.$store.state.onepayPhoneNumber = this.$route.query.UserID;
    } else if (this.$store.state.paymentName == "citizenpay") {
      this.$store.state.backendUrl = this.$store.state.backendCitizenPayUrl;
    }

    // if (this.$store.state.paymentName == "mpitesan") {
    //   this.$router.push({
    //     name: "servicemaintain",
    //   });
    // }else{
      
    //   this.$router.push({
    //     name: "category",
    //     params: { categoryName: this.contentCategory },
    //   });
    // }
    this.$router.push({
        name: "category",
        params: { categoryName: this.contentCategory },
      });
  },
};
</script>
