<template>
  <div></div>
</template>
<script>
export default {
  name: `PaymentCancel`,
  data() {
    return {
      payment: "",
    };
  },
  created() {
    this.$swal("Canceled", "Your Purchase was canceled.", "info");
    this.getPaymentName();
    if (this.$store.state.paymentName == " ") {
      this.getPaymentName();
    } else {
      this.$router.push({
        name: "home",
      });
    }
  },

  methods: {
    getPaymentName() {
      this.payment = this.$route.params.payment;
      this.$store.commit("getPaymentName", this.payment);
    },
  },
};
</script>
