<template>
  <section class="featured-home-post">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
      scoped
    />
    <section class="container">
      <div class="wrapper baydin-form">
        <h1>တစ်နှစ်စာ ဟောစာတမ်း</h1>
        <!-- {{ this.orders }} -->
        <!-- <div id="abcdefguid"></div> -->

        <!-- {{ this.user_id }}
        {{ this.getUserToken }} -->

        <!-- add div and loop it -->

        <div class="row m-0 p-0" v-for="order in orders.items" :key="order.id">
          <div class="container">
            <div
              class="card p-5 text-center"
              style="
                border-color: #eb6224 !important;
                border-radius: 20px !important;
              "
            >
              <div class="col-md-12 col-12 col-lg-12 col-xl-12 subscribe">
                <div class="baydin_title">နာမည် : {{ order.name }}</div>
                <div class="baydin_date">
                  မွေးသက္ကရာဇ် : {{ order.date_of_birth }}
                </div>
                <br />
                <div class="baydin_day">မွေးရက် : {{ order.day }}</div>
                <br />

                <!-- <div v-if="new Date(order.show_time).getTime() > now">
                  {{ countDown(order.show_time) }}
                </div> -->
                <div
                  class="btn"
                  @click="
                    $router.push({
                      name: 'orderDetail',
                      params: { detail: order },
                    })
                  "
                >
                  အားလုံးဖတ်ရန်<i
                    class="fa fa-angle-right"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
      </div>
    </section>
    <!-- end .featured-home-post -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP, ONEYEAR } from "@core/lib/http-common";

export default {
  name: `NewYearOrder`,
  data() {
    return {
      orders: [],
      user_id: 0,
      actionBtnMethod: "",
      onepayPhoneNumber: "",
      // now: new Date().getTime(),
      // countDownTimes: {
      //   hours: "",
      //   minutes: "",
      //   seconds: "",
      // },
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "userStore/isAuthenticated",
      getResponse: "userStore/getResponse",
      getUserToken: "userStore/getUserToken",
    }),
  },
  async created() {
    // window.setInterval(() => {
    //   this.now = new Date().getTime();
    // }, 1000);

    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    switch (this.actionBtnMethod) {
      case "onepay":
        await this.onepayProvider();
        break;

      default:
        await this.kpayProvider();
        break;
    }
  },

  methods: {
    // countDown(show_time) {
    //   if (new Date(show_time).getTime() > this.now) {
    //     // alert()
    //     // this.showTime = true;
    //     let distance = new Date(show_time).getTime() - this.now;
    //     // let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     let hours = Math.floor(
    //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    //     );

    //     let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //     if (hours < 10) {
    //       this.countDownTimes.hours = `0${hours}`;
    //     } else {
    //       this.countDownTimes.hours = hours;
    //     }

    //     if (minutes < 10) {
    //       this.countDownTimes.minutes = `0${minutes}`;
    //     } else {
    //       this.countDownTimes.minutes = minutes;
    //     }

    //     if (seconds < 10) {
    //       this.countDownTimes.seconds = `0${seconds}`;
    //     } else {
    //       this.countDownTimes.seconds = seconds;
    //     }

    //     return (
    //       "Avaliable in - " +
    //       this.countDownTimes.hours +
    //       ":" +
    //       this.countDownTimes.minutes +
    //       ":" +
    //       this.countDownTimes.seconds
    //     );
    //
    //   }
    // },

    async kpayProvider() {
      if (!this.isAuthenticated) {
        await this.$store.dispatch(
          "userStore/login",
          this.$route.query.kbzpay_token
        );
      }

      HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
      await HTTP.get("subscription/orders?baydintype=newyear")
        .then((response) => {
          // .then(() => {
          // let user_id = response.data.user_id;

          this.user_id = response.data.user_id;
          // document.getElementById("abcdefguid").appendChild(user_id);

          // this.uid = response.data.user_id || 1;
          let data = {
            user_id: this.user_id,
            paymentName: "KBZPAY",
            auth1: "1875BaydinUser",
            auth2: "1875BaydinUserP@ssword",
          };

          ONEYEAR.post("orders", data)
            .then(({ data }) => {
              this.orders = data;
              console.log("Order api order values:");
              console.log(this.orders);
            })
            .catch(() => {
              console.log("Unauthorize!");
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onepayProvider() {
      const data = {
        auth1: "1875BaydinUser",
        paymentName: "OnePay",
        auth2: "1875BaydinUserP@ssword",
        user_id: this.onepayPhoneNumber,
      };

      ONEYEAR.post("orders", data)
        .then(({ data }) => {
          this.orders = data;
        })
        .catch(() => {
          console.log("Unauthorize!");
        });
    },
  },
};
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 15px;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

h4 {
  color: #eb6224;
  font-weight: 500;
  /* margin-bottom: 20px; */
}

h1 {
  color: #eb6224;
  display: inline-block;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  /* margin-bottom: 20px; */
}

img {
  max-width: 100%;
}

ul {
  /* padding-left: 0; */
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

textarea {
  resize: none;
}

/* :focus {
            outline: none;
        } */

/* .wrapper {
  align-items: center;

  margin-top: 20px;
} */

.inner {
  display: flex;
  margin-left: 270px;
}

.slider {
  margin-left: -20px;
}

.option {
  color: #eb6224;
  text-align: center;
  width: 30px;
  font-weight: 300;
}

/* .baydin-form {
  background: white;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: -10px;

  margin-right: 10px;
  border: 2px solid #eb6224;
  border-radius: 25px;
} */

/* .subscribe {
  border-radius: 5px;
  width: 34%;
  border: 3px solid #e1e1e1;
  padding: 45px 35px;
  text-align: center;
  margin-right: 90px;
  box-sizing: border-box;
  margin-bottom: 20px;
} */

.btn {
  width: 100px;
  border-radius: 7.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 13px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  background: #eb6224;
  padding-bottom: 5px;
  margin-right: 15px;
}

.baydin_title {
  font-family: PT-Serif-Bold, "MyanmarAngoun", "Arial", sans-serif;
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #232323;
}

.container {
  margin-top: 50px;
}

h3 {
  text-align: center;
  margin-bottom: 38px;
  color: white;
}

@media (max-width: 590px) {
  /* .subscribe {
    width: 350px;
    margin-bottom: 20px;
  } */

  /* .baydin-form {
    height: auto;
    margin-left: auto;
    margin-top: 30px;
    padding-left: 30px;
  } */

  h1 {
    font-size: 20px;
  }

  h4 {
    font-size: 19px;
  }

  .row {
    margin-left: 0px;
  }
}

@media (max-width: 890px) {
  /* .subscribe {
    width: 350px;
    margin-bottom: 20px;
  } */

  /* .baydin-form {
    height: auto;
    width: 350px;
    margin-left: auto;
     padding-left: 30px;
     margin-top: 30px;
  } */

  .inner {
    margin-left: auto;
  }

  h1 {
    font-size: 20px;
  }

  .row {
    margin-left: 0px;
  }
}

@media (max-width: 1190px) {
}

/*# sourceMappingURL=style.css.map */
</style>
