<template>
  <!--this is oordeoo popup coupon-->

  <div v-if="showCouponContainer" class="coupon-container">
    <div class="coupon-header">
      <span>လျှော့ဈေး Coupon Code ထည့်ရန်</span>
      <button @click="showPopup = true">Claim</button>

      <a @click="hideCouponHeader" id="btn-close">
        <i class="fa fa-times-circle" aria-hidden="true"></i>
      </a>
    </div>

    <div v-if="showPopup" class="popup">
      <div class="popup-card" v-if="validCoupon && !showSuccessCoupon">
        <div class="mt-4">
          <input
            v-model="ph_no"
            placeholder="Phone Number ထည့်ရန်"
            class="input-coupon"
            @input="ph_no = ph_no.replace(/\D/g, '')"
          />

        </div>
        <div class="mt-2">
          <input
            v-model="message"
            placeholder="Coupon Code ထည့်ရန်"
            class="input-coupon"
          />
        </div>
        <div class="mt-2 btn-claim-container">
          <button @click="claimCoupon" class="btn-claim">Claim</button>
        </div>
        <a @click="showPopup = false" id="btn-exit">
          <i class="fa fa-times-circle" aria-hidden="true"></i>
        </a>
      </div>

      <!-- if coupon is wrong -->
      <div class="popup-card" v-else-if="!validCoupon && !showSuccessCoupon">
        <div class="error-coupon-text">{{ showMsg }}</div>
        <div class="try-btn">
          <button @click="tryCoupon" class="btn-claim-after">Try Again</button>
        </div>
        <a @click="showPopup = false" id="btn-exit">
          <i class="fa fa-times-circle" aria-hidden="true"></i>
        </a>
      </div>

      <!-- if coupon is success -->
      <div class="popup-card" v-if="showSuccessCoupon">
        <div class="error-coupon-text">
          Coupon လျှော့ဈေးအသုံးပြုမှုအောင်မြင်ပါသည်
        </div>
        <div class="try-btn">
          <button @click="closeCoupon" class="btn-claim-after">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.common.js";
import { mapActions } from "vuex/dist/vuex.common.js";
import category from "../../core/i18n/resource/baydin_categories.json";
import { verifyCoupon } from "../js/cupon";

export default {
  name: "Copoun",
  data() {
    return {
      //this is for ooredoo coupon
      showPopup: false,
      couponClaimed: false,
      showCouponContainer: true,
      cdata: category,
      message: "",
      ph_no : "",
      validCoupon: true,
      showSuccessCoupon: false,
      showMsg :""
    };
  },
  methods: {
    claimCoupon() {
      if (!this.message) {
        return;
      }
      verifyCoupon(this.getAppName, this.message, this.ph_no)
        .then((data) => {
          this.showSuccessCoupon = true;
          this.changeCoupon();
          this.changeDiscountCoupon(data);
        })
        .catch(({message}) => {
          this.showMsg = message;
          this.message = "";
          this.validCoupon = false;
        });
      // alert("Coupon claimed! Discount applied.");
      // You can add logic here to apply the discount to the user's session or cart.
    },
    closeCoupon() {
      this.couponClaimed = true;
      this.showPopup = false;
      this.showCouponContainer = false;
    },
    hideCouponHeader() {
      this.showCouponContainer = false;
    },
    tryCoupon() {
      this.validCoupon = true;
    },
    ...mapActions(["changeCoupon", "changeDiscountCoupon"]),
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getCouponCode", "getDiscountCoupon","getAppName"]),
  },
};
</script>

<style scoped>
/* this is for ooredoo coupon */

.coupon-container {
  text-align: center;
}

.coupon-header {
  display: flex;
  justify-content: center; /* Aligns items to the ends of the container */
  align-items: center; /* Vertically centers the items */
  gap: 20px; /* Adds spacing between items */
  font-size: 15px;
  margin: 0;
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: antiquewhite;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.coupon-header span {
  flex-grow: 1; /* Allows span to take up available space */
  text-align: center; /* Centers the text */
}

.coupon-header > button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the popup is in front */
}

.popup-card {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  z-index: 10000; /* Ensures the card is in front of everything else */
  gap: 10px;
}

#btn-close {
  font-size: 36px;
  margin: 0;
  color: black;
}

#btn-exit {
  font-size: 36px;
  margin: 0;
  color: black;
  position:absolute;
  right: 0;
  margin-right: 5px;
  top: 0;
}

.btn-claim {
  border-radius: 10px;
  background-color: #af4c5c;
  border: none;
  float: right;
  margin-right: 51px;
}

.btn-claim-after {
  border-radius: 10px;
  background-color: #af4c5c;
}

.input-coupon {
  background-color: wheat;
  padding: 11px;
  border-radius: 20px;
  font-size: 15px;
  color: black;
  border: none;
  width: 250px;
}

.error-coupon-text {
  line-height: 1.7;
  font-weight: bold;
  width: 80%;
}

.try-btn {
  width: 100%;
}

.btn-claim-container {
  width: 100%;
}
</style>
