import Axios from "axios";
import envConfig from "../config/envConfig";
import { ONEYEAR } from "@core/lib/http-common";
import { couponDiscount } from "./cupon";

// With BP Pay
export let makePayment = (datas, options) => {
    

    return new Promise((resolve, reject) => {
        let horoURL = datas[0];
        let bparams = datas[1];
        let cparams = datas[2];
        cparams = couponDiscount(cparams, options);
        
        let backendURL = datas[3];
        //let amount = datas[4];
        let order_id;
        let payment_status = false;
        // console.log(amount);
        // chat bot url
        // let getAPIUrl = envConfig.horoUrl + horoURL;
       
        let getAPIUrl;
        if (datas[datas.length - 1] != "newyearbaydin") {
            getAPIUrl = envConfig.horoUrl + horoURL;
        } else {
            getAPIUrl = horoURL;
        }
        // backend url
        let getBackendUrl = backendURL;
        //test of coupon for authorization
        if(envConfig.appEnv === 'local'){
            // Axios.defaults.headers.common['Authorization'] = Bearer 33ae28a434803ae30d954de6439fe0571fac2425886699572872e4c1e4598b7f58f9500e50b8c8c5268d2f38fed904035a0f92fef41f37ab2cc404415464267f;
            }
        Axios.post(getBackendUrl, cparams).then(({ data }) => {
            
            if (data.status == 403 || data.status == 500) {
                reject({
                    status: 500,
                });
                return;
            }
            // console.log(data);
            if (data.valid_status) {
                //amount = 0;
                bparams.delete("price");
                bparams.append("price", data.amount);
                bparams.append("payment_status", "success");
                payment_status = false;
            }
            order_id = data.order_id;
            bparams.set('price', data.amount);
            bparams.append("order_id", data.order_id);

            if (datas[datas.length - 1] == "newyearbaydin") {
                bparams.append("auth1", "1875BaydinUser");
                bparams.append("auth2", "1875BaydinUserP@ssword");
                bparams.set("amount", data.amount);
                // console.log('ok')
                ONEYEAR.post(getAPIUrl, bparams).then((response) => {
                    // console.log(response.data)
                    if (response.data.order_status == "success") {

                        resolve({
                            status: 200,
                            order_id: order_id,
                            amount: data.amount,
                        });
                    } else {
                        reject({
                            status: 500,
                        });
                    }
                });
            } else {
                // call chat bot url
                Axios.post(getAPIUrl, bparams, {
                    auth: {
                        username: "admin2021@horo.com",
                        password: "blue0cean@2021",
                    },
                }).then((response) => {
                    // console.log(response.data);
                    if (response.data.status == 200) {
                        resolve({
                            status: 200,
                            order_id: order_id,
                            amount: data.amount,
                            payment_status: payment_status,
                        });
                    } else {
                        reject({
                            status: 500,
                        });
                    }
                });
            }
            
        });
    });
};

// OnePay Direct
export let onePay = (datas) => {
    return new Promise((resolve, reject) => {
        let horoURL = datas[0];
        let bparams = datas[1];
        let cparams = datas[2];
        let backendURL = datas[3];
        //let amount = datas[4];
        let order_id;
        // console.log(amount);
        // chat bot url
        let getAPIUrl;
        if (datas[datas.length - 1] != "newyearbaydin") {
            getAPIUrl = envConfig.horoUrl + horoURL;
        } else {
            getAPIUrl = horoURL;
        }
        // backend url
        let getBackendUrl = backendURL;
        Axios.post(getBackendUrl, cparams).then(({ data }) => {
            // console.log(data);
            if (data.status == 403 || data.status == 400) {
                reject({
                    status: 500,
                });
                return;
            }

            order_id = data.order_id;
            bparams.append("order_id", data.order_id);
            bparams.set("price", data.amount);
            bparams.append("auth1", "1875BaydinUser");
            bparams.append("auth2", "1875BaydinUserP@ssword");

            // make condition and call newyear bakcend
            if (datas[datas.length - 1] == "newyearbaydin") {
                bparams.set("amount", data.amount);
                ONEYEAR.post(getAPIUrl, bparams).then((response) => {
                    if (response.data.order_status == "success") {
                        resolve({
                            status: 200,
                            order_id: order_id,
                            amount: data.amount,
                        });
                    } else {
                        reject({
                            status: 500,
                        });
                    }
                });
            } else {
                // call chat bot url
                Axios.post(getAPIUrl, bparams, {
                    auth: {
                        username: "admin2021@horo.com",
                        password: "blue0cean@2021",
                    },
                }).then((response) => {
                    // console.log(response.data);
                    if (response.data.status == 200) {
                        resolve({
                            status: 200,
                            order_id: order_id,
                            amount: data.amount,
                        });
                    } else {
                        reject({
                            status: 500,
                        });
                    }
                });
            }
        });
    });
};

// Citizenpay Direct
export let citizenPay = (datas) => {
    return new Promise((resolve, reject) => {
        let horoURL = datas[0];
        let bparams = datas[1];
        let cparams = datas[2];
        let backendURL = datas[3];
        let order_id;
        let paymentGatway;
        // // console.log(amount);
        // // chat bot url
        let getAPIUrl = envConfig.horoUrl + horoURL;
        // // backend url
        let getBackendUrl = backendURL;
        Axios.post(getBackendUrl, cparams).then(({ data }) => {
            // console.log("backend api ok");
            if (data.status != "success") {
                reject({
                    status: 500,
                });
                return;
            }

            order_id = data.orderId;
            paymentGatway = data.paymentGatewayURL;
            bparams.append("order_id", data.orderId);
            bparams.append("user_id", 1001013);
            bparams.set("price", data.amount);

            // call chat bot url
            Axios.post(getAPIUrl, bparams, {
                auth: {
                    username: "admin2021@horo.com",
                    password: "blue0cean@2021",
                },
            }).then((response) => {
                // console.log("chatbot api ok");
                // console.log(response.data);
                if (response.data.status == 200) {
                    // console.log("response ok");
                    resolve({
                        status: 200,
                        order_id: order_id,
                        paymentGatway: paymentGatway,
                    });
                } else {
                    reject({
                        status: 500,
                    });
                }
            });
        });
    });
};
