<template>
  <form
    style="display: none"
    :action="paymentPortalUrl"
    method="POST"
    name="paymentform"
  >
    <input type="hidden" name="merchant_id" :value="merchant_id" />

    <input type="hidden" name="service_name" :value="service_name" />

    <input type="hidden" name="email" :value="email" />

    <input type="hidden" name="password" :value="password" />

    <input type="hidden" name="amount" :value="amount" />

    <input type="hidden" name="order_id" :value="order_id" />
    <button type="submit" ref="submitBtn">Submit</button>
  </form>
</template>

<script>
export default {
  name: `paymentPortal`,
  data() {
    return {
      paymentPortalUrl: "",
      merchant_id: "",
      service_name: "",
      email: "",
      password: "",
      amount: 0,
      order_id: "",
    };
  },
  created() {
    this.paymentPortalUrl = this.$store.state.paymentPortalUrl;
    this.merchant_id = this.$store.state.merchant_id;
    this.service_name = this.$store.state.service_name;
    this.email = this.$store.state.email;
    this.password = this.$store.state.password;
    this.amount = this.$store.state.amount;
    this.order_id = this.$store.state.order_id;
  },
  mounted() {
    this.$refs.submitBtn.click();
  },
};
</script>

