import {Address} from "@core/lib/http-common";

export async function getDivisions() {
    let res = await Address.post('/divisions');
    return res.data;
}

export async function getTownships(nrc_id) {
    let res = await Address.post(`/townships/${nrc_id}`);
    return res.data;
}