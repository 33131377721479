<template>
  <component :is="layout">
    <Header />
    <router-view />
  </component>
</template>

<script>
const DefaultLayout = () =>
  import(/* webpackChunkName: "vsf-layout-default" */ "./layouts/Default");
  
  import Header from './components/Header.vue';

export default {
  name: `App`,
  components: {
    DefaultLayout,
    Header,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "default"}-layout`;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html {
  line-height: 1.6;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

p {
  &:not(:first-child) {
    margin-top: 1.25em;
  }
}

.mm-font {
  font-family: Unicode;
}

input::placeholder {
  color: grey !important;
}
</style>
