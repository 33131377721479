<template>
  <form
    style="display: none"
    :action="paymentPortalUrl"
    method="POST"
    name="paymentform"
  >
    <input type="hidden" name="merchant_id" :value="merchant_id" />

    <input type="hidden" name="service_name" :value="service_name" />

    <input type="hidden" name="email" :value="email" />

    <input type="hidden" name="password" :value="password" />

    <input type="hidden" name="amount" :value="amount" />

    <input type="hidden" name="order_id" :value="order_id" />

    <input type="hidden" name="paymentMethods" value="*" />

    <input type="hidden" name="hashValue" :value="hashPaymentData()" />
    <button type="submit" ref="submitBtn">Submit</button>
  </form>
</template>

<script>
import CryptoJS from "crypto-js";

export default {
  name: `paymentPortalV3`,
  data() {
    return {
      paymentPortalUrl: "",
      merchant_id: "",
      service_name: "",
      email: "",
      password: "",
      amount: 0,
      order_id: "",
      key: "",
    };
  },
  created() {
    this.paymentPortalUrl = this.$store.state.paymentPortalV3Url;
    this.merchant_id = this.$store.state.merchant_id;
    this.service_name = this.$store.state.service_name;
    this.email = this.$store.state.email;
    this.password = this.$store.state.password;
    this.key = this.$store.state.merchant_key;
    this.amount = this.$store.state.amount;
    this.order_id = this.$store.state.order_id;
  },
  mounted() {
    this.$refs.submitBtn.click();
  },
  methods: {
    hashPaymentData() {
      const data = [
        { email: this.email },
        { password: this.password },
        { merchant_id: this.merchant_id },
        { service_name: this.service_name },
        { order_id: this.order_id },
        { amount: "" + this.amount },
        { paymentMethods: "*" },
      ];

      const sorted_data = data.sort((a, b) => {
        let c = Object.values(a)[0];
        let d = Object.values(b)[0];

        return c === d ? 0 : c < d ? -1 : 1;
      });

      const implode_str = sorted_data.reduce((total, item) => {
        return (total += "" + Object.values(item)[0]);
      }, "");

      let sec_key = this.key;
      let sign_data = CryptoJS.HmacSHA1(implode_str, sec_key).toString();
      sign_data = sign_data.toUpperCase();
      return encodeURI(sign_data);
    },
  },
};
</script>

