import Axios from "axios";
import envConfig from "../config/envConfig";

export const couponDiscount = (datas, options) => {
  if (options.coupon_code) {
    datas.coupon_code = options.coupon_code;
  }
  console.log(options.coupon_code);
  return datas;
};

export let verifyCoupon = (appName, coupon, phoneNo) => {
  const cparams = {
    appName,
    coupon,
    phoneNo,
  };

  return new Promise((resolve, reject) => {
    

    Axios.post(envConfig.appBackendUrl + "coupon", cparams).then(
      ({ data }) => {
        if (data.status == 403 || data.status == 500) {
          reject({
            status: 500,
            message : data.message,
          });
          return;
        }
        return data.status == 200 ? resolve(data.coupon_code) : reject("Invalid coupon.");
      }
    );
  });
};
