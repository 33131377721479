export const CUST_REQUEST = 'CUST_REQUEST'
export const CUST_SUCCESS = 'CUST_SUCCESS'
export const CUST_ERROR = 'CUST_ERROR'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const LOGOUT = 'LOGOUT'
