<template>
    <div class="w-100 t-wrapper pt-4 pb-4" style="min-height: 100%;">
        <div class="container">
            <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                <h3 class="mm-font text-center mb-1">ဖူးစာဖက်</h3>
                <div class="pane-line"></div>
                <h5 class="text-center mt-2 mb-4 mm-font">
                    သင်နှင့်သင့်ချစ်သူ၏ ရာသီခွင်တိုက်ကြည့်ရအောင်
                </h5>
                <div class="w-100 h-100 mt-3">
                    <div class="d-flex align-items-center justify-content-center" style="gap: 20px;">
                        <div class="sign-container">
                            <p class="text-center p-0 m-0" :style="first_sign_select ? 'display: none' : 'display: block'">Your sign</p>
                            <img 
                                :src="'https://1875baydin.com/'+(first_sign_select ? signs[first_sign_id]['img_path'] : '')"  
                                class="select-sign-img" 
                                :style="first_sign_select ? 'display: block' : 'display: none'"
                            />
                        </div>
                        <img src="https://1875baydin.com/images/love_calculator/love.png" width="30px" height="30px"/>
                        <div class="sign-container">
                            <p class="text-center p-0 m-0" :style="second_sign_select ? 'display: none' : 'display: block'">Partner's sign</p>
                            <img 
                                :src="'https://1875baydin.com/'+(second_sign_select ? signs[second_sign_id]['img_path'] : '')"  
                                class="select-sign-img" 
                                :style="second_sign_select ? 'display: block' : 'display: none'"
                            />
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center text-white mt-4" style="gap: 20px">
                        <p class="text-center text-white p-0 m-0" style="width: 70px">{{first_sign_select ? signs[first_sign_id]["eng"] : ""}}</p>
                        <i class="fa fa-plus"></i>
                        <p class="text-center text-white p-0 m-0" style="width: 70px">{{second_sign_select ? signs[second_sign_id]["eng"] : ""}}</p>
                    </div>
                    <div class="mt-5 w-100">
                        <div :style="result_shown ? 'display: none' : 'display: block'">
                            <div class="signs-grid-container">
                                <div class="sign-gp" v-for="sign in signs" :key="sign.value" @click="selectSign(sign.value)">
                                    <img
                                        class="sign-grid-img"
                                        :src="'https://1875baydin.com/'+sign.img_path"
                                    />
                                    <div class="d-flex flex-column text-center">
                                        <p class="sign-grid-name m-0 p-0">{{sign.eng}}</p>
                                        <p class="sign-grid-name">({{sign.date_range}})</p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 d-flex justify-content-center align-items-center p-2" style="gap: 20px">
                                <span class="love-btn" @click="reset()">Reset</span>
                                <span class="love-btn" id="love-calculate" @click="calculate()">Calculate</span>
                            </div>
                        </div>
                        <div class="w-100 h-100" :style="loading ? 'display: block' : 'display: none'">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="https://1875baydin.com/images/love_calculator/heart.gif" style="width: 40px" height="40px"/>
                                <label style="color: white; font-size: 90%; font-family: 'Montserrat', sans-serif;">Calculating...</label>
                            </div>
                        </div>
                        <div class="w-100 love-calculate-result-wrapper" :style="(result_shown && !loading) ? 'display: block' : 'display: none'">
                            <div class="love-calculate-result">
                                <div class="love-result-rect-bg">
                                    <label class="love-result-name">Love</label>
                                    <div class="love-result-percent-container">
                                         <!-- width of love-result-percent must be set when calculated -->
                                        <div class="love-result-percent" :style="'width:'+result.love+'%'"></div>
                                    </div>
                                    <label class="love-result-percent-text">{{result.love}}%</label>
                                </div>

                                <div class="love-result-rect-bg">
                                    <label class="love-result-name">Fight</label>
                                    <div class="love-result-percent-container">
                                        <!-- width of love-result-percent must be set when calculated -->
                                        <div class="love-result-percent" :style="'width:'+result.fight+'%'"></div>
                                    </div>
                                    <label class="love-result-percent-text">{{result.fight}}%</label>
                                </div>

                                <div class="love-result-rect-bg">
                                    <label class="love-result-name">Sex</label>
                                    <div class="love-result-percent-container">
                                        <!-- width of love-result-percent must be set when calculated -->
                                        <div class="love-result-percent" :style="'width:'+result.sex+'%'"></div>
                                    </div>
                                    <label class="love-result-percent-text">{{result.sex}}%</label>
                                </div>

                                <div class="love-result-rect-bg">
                                    <label class="love-result-name">Care</label>
                                    <div class="love-result-percent-container">
                                        <!-- width of love-result-percent must be set when calculated -->
                                        <div class="love-result-percent" :style="'width:'+result.care+'%'"></div>
                                    </div>
                                    <label class="love-result-percent-text">{{result.care}}%</label>
                                </div>
                                <span class="mt-3 back-btn" @click="back">&#8592; back</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        "name": "CompatabilityLove",
        data() {
            return {
                signs: null,
                first_sign_select: false,
                second_sign_select: false,
                first_sign_id: null,
                second_sign_id: null,
                result_shown: false,
                loading: false,
                result: {
                    love: 0,
                    fight: 0,
                    sex: 0,
                    care: 0
                }
            }
        },

        async created() {
            let signs_in_array = (await axios.post('https://1875baydin.com/api/signs')).data;
            this.signs = signs_in_array.reduce((obj, cur_sign) => {
                obj[""+cur_sign.value] = {...cur_sign};
                return obj;
            }, {});
        },

        methods: {
            selectSign(id) {
                if(!(this.first_sign_select && this.second_sign_select)) {
                    if(!this.first_sign_select) {
                        this.first_sign_id = id;
                        this.first_sign_select = true;
                    }
                    else {
                        this.second_sign_id = id;
                        this.second_sign_select = true;
                    }
                }
            },

            reset() {
                this.first_sign_select = false;
                this.second_sign_select = false;
            },

            back() {
                this.$router.push({
                    name: "withPaymenthome",
                    params: {
                        payment: this.$route.query.payment
                    }
                });
            },

            async calculate() {
                if(this.first_sign_id && this.second_sign_id) {
                    this.showLoading();
                    let result = await this.getResult();
                    setTimeout(() => this.showResult(result), 3000);
                }
            },

            async getResult() {
                let result = (await axios.post(`https://1875baydin.com/api/compatability-love-result/${this.first_sign_id}/${this.second_sign_id}`)).data;
                return result;
            },

            showLoading() {
                this.loading = true;
                this.result_shown = true;
            },

            async showResult(result) {
                this.loading = false;
                this.result = {...result};
            }
        }
    }
</script>

<style scoped>
    .t-wrapper {
        background: #031d2e !important;
    }

    .t-wrapper * {
        color: white;
    }

    div.pane-line {
        width: 50px;
        height: 5px;
        border-radius: 30px;
        background: #ff7010 !important;
    }

    .sign-container {
        width: 100px;
        height: 100px;
        border: 2px solid white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .signs-grid-container {
        display: grid;
        width: 90%;
        grid-gap: 10px;
        margin: auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        animation-name: modal-zoom;
        animation-duration: .2s;
    }

    .sign-gp {
        width: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: center;
    }

    .select-sign-img {
        position: absolute;
        z-index: 2;
        width: 80px;
        height: 80px;
        margin: auto;
    }

    .sign-grid-img {
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .sign-grid-name {
        font-size: 80%;
        color: white;
    }

    p.sign-grid-name:nth-child(2) {
        color: grey;
    }

    .sign-gp:active {
        opacity: .7;
    }

    .love-btn {
        outline: none;
        border: 1px solid #fff08d;
        padding: 5px 10px;
        background: transparent;
        color: white;
        border-radius: 3px;
        cursor: pointer;
    }

    .love-btn:active {
        opacity: .7;
    }

    .love-loading-container {
        width: 100%;
    }

    .love-calculate-result-wrapper {
        width: 100%;
        animation-name: modal-zoom;
        animation-duration: .2s;
    }

    .love-calculate-result {
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .love-result-rect-bg {
        width: 100%;
        padding: 10px 10px;
        background-color: white;
        border-radius: 30px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .love-result-name {
        width: 50px;
        text-align: right;
        color: black;
        font-family: 'Montserrat', sans-serif;
        font-size: 90%;
    }

    .love-result-percent-container {
        /* 100% - width of love-result-name + width of love-result-percent-text*/
        position: relative;
        width: calc(100% - 100px);
        height: 5px;
        border-radius: 30px;
        background-color: black;
        overflow: hidden;
    }

    /* width of love-result-percent must be set when calculated */
    .love-result-percent {
        height: 100%;
        width: 0;
        border-radius: 30px;
        background-color: #33b5e5;
        position: absolute;
        top: 0;
        animation-name: percent;
        animation-duration: 2s;
        animation-timing-function: forwards;
    }

    .love-result-percent:hover {
        width: 300px;
    }

    .love-result-percent-text {
        width: 50px;
        color: black;
    }

    @keyframes percent {
        0% {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    @keyframes modal-zoom {
        0% {
            transform: scale(0, 0);
        }

        100% {
            transform: scale(1, 1);
        }
    }
</style>
