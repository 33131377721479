<template>
  <div></div>
</template>
<script>
export default {
  name: `RedirectHome`,

  created() {
    this.$router.push({
      name: "home",
    });
  },
};
</script>