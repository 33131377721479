<template>
  <div></div>
</template>

<script>
import envConfig from "../config/envConfig";

export default {
  name: `withPaymenthome`,
  data() {
    return {
      payment: "",
    };
  },
  created() {
    this.getPaymentName();

    if (this.$store.state.paymentName == "mpitesan") {
      this.$store.state.backendUrl = this.$store.state.backendMpiteSanUrl;
    } else if (this.$store.state.paymentName == "onepay") {
      this.$store.state.backendUrl = this.$store.state.backendOnepayUrl;
      this.$store.state.onepayPhoneNumber = this.$route.query.UserID;
    } else if (this.$store.state.paymentName == "citizenpay") {
      this.$store.state.backendUrl = this.$store.state.backendCitizenPayUrl;
    } else if (this.$store.state.paymentName == "bppay") {
      this.$store.state.backendUrl = this.$store.state.backendBPpayUrl;

      this.$store.state.merchant_id = envConfig.bpPayAllMerchantId;
      this.$store.state.service_name = envConfig.bpPayAllServiceName;
      this.$store.state.email = envConfig.bpPayAllEmail;
      this.$store.state.password = envConfig.bpPayAllPassword;
      this.$store.state.merchant_key = envConfig.bpPayAllKey;

      return this.redirectService(this.$route.query.service ?? null);
    }

    // if (this.$store.state.paymentName == "bppay") {
    //   this.$router.push({
    //     name: "servicemaintain",
    //   });
    // } else {
    //   this.$router.push({
    //     name: "home",
    //   });
    // }


    this.$router.push({
      name: "home",
    });
  },

  methods: {
    getPaymentName() {
      this.payment = this.$route.params.payment;
      this.$store.commit("getPaymentName", this.payment);
    },

    redirectService(service) {
      if(service) {
        this.$router.push({
          name: "category",
          params: { categoryName: service },
        });
      }
      else {
        this.$router.push({
          name: "home"
        });
      }
    }
  },
};
</script>
