<template>
    <div v-if="couponDecision" class="margin-header">
      <Coupon/>
    <div>
      <!-- {{ couponDecision }} -->
    </div>
    </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex/dist/vuex.common.js';
  export default {
    name: 'Header',
    components: {
      Coupon
    },
    props: {
    // Define a prop to accept the category data from the parent component
    category: {
      type: Object,
      required: true
    }
      },
    computed: {
    ...mapGetters(['couponDecision']),
    },
    mounted() {
      // console.log(typeof this.couponDecision)
      },
  };

  import Coupon from './Coupon.vue';
  </script>
  
  <style scoped>
   .margin-header {
    margin-bottom: 50px;
   }
  </style>
  