<template>
  <div></div>
</template>
<script>
import Axios from "axios";
export default {
  data() {
    return {
      paymentMethod: "",
      merchantRefId: "",
    };
  },
  created() {
    this.paymentMethod = this.$route.params.paymentMethod;
    this.merchantRefId = this.$route.params.merchatRefId;
    this.$store.commit("getPaymentName", this.paymentMethod);

    if (this.$store.state.paymentName == "mpitesan") {
      this.$store.state.backendUrl = this.$store.state.backendMpiteSanUrl;
    } else if (this.$store.state.paymentName == "onepay") {
      this.$store.state.backendUrl = this.$store.state.backendOnepayUrl;
      this.$store.state.onepayPhoneNumber = this.$route.query.UserID;
    } else if (this.$store.state.paymentName == "citizenpay") {
      this.$store.state.backendUrl = this.$store.state.backendCitizenPayUrl;
    }

    let checkStatusURL = this.$store.state.citizenpayCheckPaymentStatusURL;
    let data = {
      RefId: this.merchantRefId,
    };

    Axios.post(checkStatusURL, data)
      .then(({ data }) => {
        if (data.paymentStatus == "success") {
          this.$router.push({
              name: "PaymentSuccess",
              params: { payment: this.paymentMethod },
              query: {category_id: data.category_id ?? null}
            });
        } else if (data.paymentStatus == "canceled") {
          this.$router.push({
            name: "PaymentCancel",
            params: { payment: this.paymentMethod },
          });
        } else {
          this.$router.push({
            name: "PaymentFail",
            params: { payment: this.paymentMethod },
          });
        }
      })
      .catch(() => {
        this.$router.push({
          name: "PaymentFail",
          params: { payment: this.paymentMethod },
        });
      });
  },
};
</script>
