<template>
  <div></div>
</template>
<script>
export default {
  name: `PaymentSuccess`,
  data() {
    return {
      payment: "",
    };
  },
  created() {
    if(this.$route.query.category_id) {
      this.getPaymentName();
      if(this.$route.query.category_id == "3479") {
        this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ။ ဖူးစာဖက်ပက်ကေ့ချ်အား ရယူနိုင်ပါပီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။", "success");
        this.$router.push({ 
          name: "Compatability Love",
          query: {payment: this.$route.params.payment}
        });
      }
      else if (this.$route.query.category_id == "3478") {
        this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ။ တစ်နေ့တာ တားရော့ကံကြမ္မာ ရယူနိုင်ပါပီ။ အဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။", "success");
        this.$router.push({
            name: "Tarot Shuffle",
            query: {payment: this.$route.params.payment}
        });
      }
      else if(this.$route.query.category_id == "11001101") {
        this.$swal(
          "Your Purchase Success",
          "ဟောစာတမ်းအဖြေကို အဖြေများတွင် သွားရောက်ကြည့်ရှုနိုင်ပါသည်။\nအဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။",
          "success"
        );
        setTimeout(() => {
          this.$router.push({
            name: "home",
          });
        }, 2000);
      }
      else {
        this.$router.push({
          name: "home",
        });
      }

      return;
    }


    this.$swal(
      "Your Purchase Success",
      "အဖြေကို Form ဖြည့်ထားသောဖုန်းနံပါတ်အား ၂ရက်အတွင်းပေးပို့မည်ဖြစ်ပါသည်။\nအဆင်မပြေမှုများရှိပါက 09456880335 သို့ဆက်သွယ်ပါရန်။",
      "success"
    );
    this.getPaymentName();
    if (this.$store.state.paymentName == " ") {
      this.getPaymentName();
    } else {
      this.$router.push({
        name: "withPaymenthome",
        params: {
          payment: this.$route.params.payment
        }
      });
    }
  },

  methods: {
    getPaymentName() {
      this.payment = this.$route.params.payment;
      this.$store.commit("getPaymentName", this.payment);
    },
  },
};
</script>
